@font-face {
    font-family: 'Century Gothic';

    font-style: normal;
    font-weight: normal;

    src: url("@assets/fonts/CenturyGothic/CenturyGothic.woff2") format("woff2"),
    url("@assets/fonts/CenturyGothic/CenturyGothic.woff") format("woff"),
    url("@assets/fonts/CenturyGothic/CenturyGothic.ttf") format("truetype"),
    url("@assets/fonts/CenturyGothic/CenturyGothic.eot") format("embedded-opentype");
}

@font-face {
    font-family: 'Century Gothic';

    font-style: normal;
    font-weight: bold;

    src: url("@assets/fonts/CenturyGothic/CenturyGothic-Bold.woff2") format("woff2"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Bold.woff") format("woff"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Bold.ttf") format("truetype"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Bold.eot") format("embedded-opentype");
}

@font-face {
    font-family: 'Century Gothic';

    font-style: italic;
    font-weight: bold;

    src: url("@assets/fonts/CenturyGothic/CenturyGothic-BoldItalic.woff2") format("woff2"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-BoldItalic.woff") format("woff"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-BoldItalic.ttf") format("truetype"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-BoldItalic.eot") format("embedded-opentype");
}

@font-face {
    font-family: 'Century Gothic';

    font-style: italic;
    font-weight: normal;

    src: url("@assets/fonts/CenturyGothic/CenturyGothic-Italic.woff2") format("woff2"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Italic.woff") format("woff"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Italic.ttf") format("truetype"),
    url("@assets/fonts/CenturyGothic/CenturyGothic-Italic.eot") format("embedded-opentype");
}

body {
    margin: 0;
    font-family: "Century Gothic", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

img, video {
    max-width: 100%;

    height: auto;
}

button, input {
    appearance: none;
    padding: 0;

    font-family: "Century Gothic", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea::placeholder {
    appearance: none;

    font-family: "Century Gothic", sans-serif;
}
