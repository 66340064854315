.alert {
    position: fixed;


    left: 0;
    right: 0;
    bottom: 10px;

    margin: 0 auto;

    display: flex;

    flex-direction: column;

    width: min(90dvw, 450px);

    background-color: white;

    box-shadow: 0 16px 32px 1px rgba(87, 87, 87, 0.62);

    padding: 1rem;

    border-radius: .5rem;
}

.title {
    margin-top: 0;
    margin-bottom: .25rem;
}

.body {
    margin: .25rem;

    max-height: 10lh;

    overflow-y: auto;
}

.acceptButtons {
    display: flex;

    justify-content: space-evenly;
    align-items: center;

    gap: 1rem;

    margin-top: .5rem;
}
