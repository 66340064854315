.form {
    display: flex;

    flex-direction: column;

    gap: 2em;
}

.title {
    margin: 0;

    color: #405C65;

    text-align: center;
}

.fieldSet {

}

.submitButton {
    display: flex;

    justify-content: center;
}

.group {
    gap: .5em;

    font-size: 1.5em;
}

.wrapper {
    padding: 2em 1em;

    background-color: white;

    border-radius: .5em;

    box-shadow: 0 1em 6em 0 #0000002D;
}

.input {
    border: 1px solid #DBDBDB;

    background: #FDFDFD;

    border-radius: .25em;
}

.input:focus {
    outline: none;

    border: 2px solid #808080;
}

.errors > * {
    margin-bottom: 1em;
}
