.group {
    display: flex;
}

.input {
    font-size: calc(1em + .390625vw);

    text-align: center;

    max-width: calc(1.6em + .390625vw);

    padding: .25em .5em;
}
