.button {
    appearance: none;

    padding: 1em 2em;

    color: white;
    background-color: #3F5B64;

    border: none;
    border-radius: 3em;

    cursor: pointer;
}
