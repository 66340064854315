.button {
    appearance: none;

    padding: 1em 3em;

    color: white;
    background-color: #09A9A7;

    border: none;

    cursor: pointer;
}

.button:disabled {
    background-color: grey;
}
