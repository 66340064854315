.screen {
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-size: cover;
    background: url("@assets/media/backgrounds/TwoFactorAuthScreenBackground.png") no-repeat;

    display: flex;

    justify-content: center;
    align-items: center;
}

.logoutButton {
    position: fixed;

    top: 1em;
    left: 1em;

    display: flex;

    align-items: center;
}
